// ImageUpload.js
import React, { useState } from "react";
import imgUpLoadeIcon from "../assets/images/upLoadeIcon.png"; // 이미지 경로 수정

const ImageUpload = ({ title, size, index, setSelectedDeviceImage }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [dragging, setDragging] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setSelectedDeviceImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setSelectedDeviceImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  console.log("title", title, "size", size, "index", index);

  console.log("selectedImage", selectedImage);
  return (
    <dl className="img--up--load">
      <dt>
        {title}
        {/* <p>{title} <span>{index} img</span></p> */}
      </dt>
      <dd
        className={`upload-container ${dragging ? "active" : ""}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!selectedImage && (
          <>
            <p className="file--up--load--txt">
              <img src={imgUpLoadeIcon} alt="imgUpLoad" />
              <span>{title} 업로드</span>
            </p>
            <p className="img--txt">
              {title}를 끌어오세요.<span>{size}</span>
            </p>
          </>
        )}
        <input
          type="file"
          accept="image/*"
          className="upload-input"
          onChange={handleImageChange}
        />
        {selectedImage && (
          <img src={selectedImage} alt="Selected" className="preview-image" />
        )}
      </dd>
    </dl>
  );
};

export default ImageUpload;
