import "../styles/UserInfo.scss";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";

// 이미지
import searchIcon from "../assets/images/searchIcon.png";
import checkImg from "../assets/images/check.png";
import kycNone from "../assets/images/kycNone.png";
// 컴포넌트
import Copy from "../components/Copy";
import TokenType from "../components/TokenType";
import Cookies from "js-cookie";

function UserInfo() {
  const location = useLocation();
  const userDetailInfo = location.state ? location.state.userDetailInfo : null;
  const gameName = location.state ? location.state.gameName : null;

  const serverApi = process.env.REACT_APP_AWS_API;
  // 토큰 정보
  const token = Cookies.get("token");
  // 드롭다운 메뉴의 표시 여부를 관리하는 상태
  const [isActive, setIsActive] = useState(false);
  const [selectedGame, setSelectedGame] = useState("");
  // selectedGame 에 따라 game_list 에서 선택된 게임 정보를 가져옴
  const [selectedGameNickname, setSelectedGameNickname] = useState("");
  const [selectedGameId, setSelectedGameId] = useState("");
  // 게임별 경기 정보 조회
  const [gameMatchInfo, setGameMatchInfo] = useState([]);
  // search_keyword
  const [searchKeyword, setSearchKeyword] = useState("");
  // 검색 버튼 클릭 핸들러
  const onSearchButtonClick = () => {
    setActivePage(1);
    getGameMatchInfo();
  };

  // 네트워크별 주소
  let txidLink;
  let tournamentLink;
  switch (userDetailInfo?.network) {
    case "Polygon":
      txidLink = "https://polygonscan.com/tx/";
      tournamentLink = "https://polygon.miracleplay.gg/tournament-detail/";
      break;
    case "Avalanche":
      txidLink = "https://avascan.info/blockchain/c/tx/";
      tournamentLink = "https://avalanche.miracleplay.gg/tournament-detail/";
      break;
    case "Base":
      txidLink = "https://basescan.org/tx/";
      tournamentLink = "https://base.miracleplay.gg/tournament-detail/";
      break;
    default:
      break;
  }

  // 클릭한 게임네임에 따라 게임 닉네임과 아이디를 설정
  const handleGameInfo = (selectedGame) => {
    const selectedGameInfo = userDetailInfo.game_list.find(
      (game) => game.name === selectedGame
    );
    setSelectedGameNickname(selectedGameInfo?.nickname);
    setSelectedGameId(selectedGameInfo?.username);
  };

  // 드롭다운 메뉴를 토글하는 함수
  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const games = [
    "BUBBLE SHOOTER",
    "MIRACLE BINGO",
    "BATTLE TANK",
    "MIRACLE SLIDE",
    "BATTLE GROUND",
    "VALORANT",
    "COUNTER STRIKE2",
    "DOTA2",
    "MODERN WARFARE2",
    "MIRACLE GOLF",
    "BEAR BAKER",
    "STORM GATE",
  ];

  // 게임 선택 핸들러
  const handleSelectGame = (gameName) => {
    setSelectedGame(gameName);
    setIsActive(false); // 게임을 선택하면 드롭다운 메뉴를 닫음
  };
  // 페이지 관련
  const [activePage, setActivePage] = useState(1); // 현재 활성화된 페이지
  const [totalPages, setTotalPages] = useState(0); // 총 페이지 수
  const pagesPerBlock = 5; // 한 번에 보여질 페이지 번호 수
  const [pageNumbers, setPageNumbers] = useState([]); // 표시될 페이지 번호 배열

  // 페이지 번호 배열 업데이트
  useEffect(() => {
    const startPage =
      Math.floor((activePage - 1) / pagesPerBlock) * pagesPerBlock + 1;
    const endPage = Math.min(startPage + pagesPerBlock - 1, totalPages);
    const newPageNumbers = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
    setPageNumbers(newPageNumbers);
  }, [activePage, totalPages]);

  // 페이지 변경 핸들러
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
  };
  // ===================================================
  // 게임 정보 조회
  // ===================================================
  const getGameMatchInfo = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/game/${selectedGameId}/tournament/list?search_keyword=${searchKeyword}&page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("gameMatchInfo", res.data);
      setTotalPages(Math.ceil(res.data.total_cnt / 20) || 1);
      setGameMatchInfo(res.data);
    } catch (error) {
      console.error("gameMatchInfoError", error);
    }
  };
  // 처음 렌더링시 userDetail에서 받은 gameName을 selectedGame으로 설정
  useEffect(() => {
    setSelectedGame(gameName);
    handleGameInfo(selectedGame);
    setSearchKeyword("");
    setActivePage(1);
    getGameMatchInfo();
  }, [gameName]);
  // 페이지 변경시 게임 정보 조회
  useEffect(() => {
    getGameMatchInfo();
  }, [activePage]);
  // 클릭한 게임네임이 달라지면 게임 정보를 업데이트
  useEffect(() => {
    setSearchKeyword("");
    setActivePage(1);
    getGameMatchInfo();
    handleGameInfo(selectedGame);
  }, [selectedGame, selectedGameId]);

  console.log("userDetailInfo", userDetailInfo);
  console.log("totalPages", totalPages);
  console.log("selectedGame", selectedGame);
  console.log("searchKeyword", searchKeyword);
  return (
    <>
      <div className="user--info">
        <div className="user--info--inner">
          <div className="header--box">
            <div className="main--title">
              유저 정보
              <TokenType tokenType={userDetailInfo?.network} />
            </div>
          </div>
          {/* header--box --END-- */}
          <div className="user--info--body--box">
            <dl>
              <dt>닉네임</dt>
              <dd>
                {userDetailInfo?.name}
                <Copy text={userDetailInfo?.name} />
              </dd>
            </dl>
            <dl>
              <dt>지갑주소</dt>
              <dd>
                <span>
                  {userDetailInfo?.wallet_address}
                  <Copy text={userDetailInfo?.wallet_address} />
                </span>
              </dd>
            </dl>
            <dl>
              <dt>IP주소</dt>
              <dd>{userDetailInfo?.ip_address}</dd>
            </dl>
            <dl>
              <dt>게임</dt>
              <dd>
                <div className={isActive ? "drop--box active" : "drop--box"}>
                  <div className="drop--view_text" onClick={toggleDropdown}>
                    {selectedGame}
                  </div>
                  <ul className={isActive ? "active" : ""}>
                    {userDetailInfo?.game_list?.map((game, index) => (
                      <li
                        key={index}
                        className={`${game.username ? "" : "disabled"}`}
                        onClick={() =>
                          game.username && handleSelectGame(game.name)
                        }
                      >
                        {game.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>게임 닉네임</dt>
              <dd>
                {selectedGameNickname ? selectedGameNickname : "-"}
                <Copy text={selectedGameNickname} />
              </dd>
            </dl>
            <dl>
              <dt>게임 아이디</dt>
              <dd>
                {selectedGameId ? selectedGameId : "-"}
                <Copy text={selectedGameId} />
              </dd>
            </dl>
          </div>
          {/* user--info--body--box --END-- */}
          <div className="header--box">
            <div className="main--title">경기기록</div>
            <div className="search--box">
              <input
                type="text"
                placeholder="Type here..."
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onSearchButtonClick(event.target.value);
                  }
                }}
              />
              <button className="search--btn" onClick={onSearchButtonClick}>
                <img src={searchIcon} alt="searchIcon" />
              </button>
            </div>
          </div>
          {/* header--box --END-- */}
          <div className="game--record--list">
            <div className="record--list--table">
              <table>
                <thead>
                  <tr>
                    <th>토너먼트 번호</th>
                    <th>토너먼트 타임</th>
                    <th>획득 점수</th>
                    <th>Top Score</th>
                    <th>Total Score</th>
                    <th>AVG Score</th>
                    <th>최종순위</th>
                    <th>상금액</th>
                    <th>보상지급여부</th>
                    <th>토너먼트 TXID</th>
                    <th>토너먼트 Link</th>
                  </tr>
                </thead>
                <tbody>
                  {gameMatchInfo?.data_list?.map((row, index) => (
                    <tr key={index}>
                      <td>{row.tournament}</td>
                      <td>{row.tournament_type}</td>
                      {/* score_method 에 따라 획득 점수를 보여줍니다. Top,Total,Avg*/}
                      {row.score_method === "Top Score" ? (
                        <td>{row.top_score}</td>
                      ) : row.score_method === "Total Score" ? (
                        <td>{row.score}</td>
                      ) : (
                        <td>{Math.floor(row.avg_score)}</td>
                      )}
                      <td>{row.top_score}</td>
                      <td>{row.score}</td>
                      <td>{Math.floor(row.avg_score)}</td>
                      <td>{row.rank ? row.rank : "-"}</td>
                      <td>${parseFloat(row.dollar_money).toFixed(2)}</td>
                      <td>
                        {row.rewarded ? (
                          <img src={checkImg} alt="Verified" />
                        ) : (
                          <img src={kycNone} alt="Not Verified" />
                        )}
                      </td>
                      <td>
                        {row.tx_id ? (
                          <a
                            href={`${txidLink}${row.tx_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="txid"
                          >
                            TXID
                          </a> // txid가 true일 때
                        ) : (
                          <img src={kycNone} alt="Not Verified" /> // txid가 false일 때
                        )}
                      </td>
                      <td>
                        <Link
                          className="link--btn"
                          to={`${tournamentLink}${row.tournament}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          이동하기
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ul className="pagination">
              <li
                className="page-item prev2"
                onClick={() => handlePageClick(1)}
              >
                처음
              </li>
              <li
                className={`page-item prev ${
                  activePage === 1 ? "disabled" : ""
                }`}
                onClick={() => handlePageClick(Math.max(activePage - 1, 1))}
              >
                이전
              </li>
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    activePage === number ? "active" : ""
                  }`}
                  onClick={() => handlePageClick(number)}
                >
                  {number}
                </li>
              ))}
              <li
                className="page-item next"
                onClick={() =>
                  handlePageClick(Math.min(activePage + 1, totalPages))
                }
              >
                다음
              </li>
              <li
                className="page-item next2"
                onClick={() => handlePageClick(totalPages)}
              >
                끝
              </li>
            </ul>
          </div>
          {/* game--record--list --END-- */}
        </div>
      </div>
    </>
  );
}

export default UserInfo;
