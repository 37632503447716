import React, { useEffect, useState } from "react";
import "../styles/Login.scss";
import videoCms from "../assets/video/videoCms.mp4";
import logo from "../assets/images/miracleplay_bi_chrome_line.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Login = ({ onLogin }) => {
  const serverApi = process.env.REACT_APP_AWS_API;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [idRemember, setIdRemember] = useState(false);
  const [error, setError] = useState("");

  // 아이디 기억하기 함수
  const handleIdRemember = () => {
    setIdRemember(!idRemember);
  };
  // 아이디 기억하기 저장 되어 있는지 확인 후 실행
  useEffect(() => {
    const id = Cookies.get("id");
    if (id) {
      setUsername(id);
      setIdRemember(true);
    }
  }, []);

  // 로그인 상태 확인
  useEffect(() => {
    const isLoggedIn = Cookies.get("isLoggedIn");
    if (isLoggedIn) {
      onLogin();
    }
  }, [onLogin]);

  // 로그인 실행 함수
  const handleLogin = async () => {
    try {
      const res = await axios.post(
        `${serverApi}/api/cms/login?username=${username}&password=${password}`,
        {}
      );
      const token = res.data.token;
      console.log("로그인 성공", res);
      Cookies.set("isLoggedIn", "true", { expires: 1 });
      Cookies.set("token", token, { expires: 1 });
      if (idRemember) {
        Cookies.set("id", username);
      }
      onLogin();
    } catch (error) {
      console.log("로그인 실패", error.response ? error.response.data : error);
      setError("로그인에 실패했습니다.");
    }
  };

  // 폼 제출 핸들러
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      handleLogin();
    } else {
      setError("아이디와 비밀번호를 입력해주세요.");
    }
  };
  console.log("username", username);
  console.log("password", password);
  console.log("idRemember", idRemember);
  return (
    <div className="login">
      <div className="login__container">
        <div className="video--left">
          <img src={logo} alt="logo" />
          <video src={videoCms} autoPlay muted loop playsInline></video>
        </div>
        <div className="login--input--box">
          <div className="login--input--box--center">
            <p className="logo_txt">
              미라클플레이 관리자 페이지
              <span>아이디 패스워드를 입력해주세요</span>
            </p>
            <form className="login__input-box" onSubmit={handleSubmit}>
              <div className="input-box__id">
                <p>아이디</p>
                <input
                  className="input-box__id-input"
                  placeholder="아이디를 입력해주세요"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="input-box__pw">
                <p>비밀번호</p>
                <input
                  type="password"
                  className="input-box__pw-input"
                  placeholder="비밀번호를 입력해주세요."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && <p className="login__error">{error}</p>}
              <label class="checkbox" onChange={handleIdRemember}>
                <input type="checkbox" checked={idRemember} />
                <span class="box"></span>
                <p>아이디 기억 하기</p>
              </label>
              <button type="submit" className="input-box__submit">
                로그인
              </button>
              <div className="join--txt">
                계정이 없으신가요?<Link>회원가입</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
