import React from "react";
import axios from "axios";
import "./DeleteModal.scss";
import Cookies from "js-cookie";
const DeleteModal = ({ onClose, userListId, getUserList }) => {
  console.log("userListId", userListId);

  const token = Cookies.get("token");
  const serverApi = process.env.REACT_APP_AWS_API;

  // 유저 삭제 실행 함수
  const deleteUser = async () => {
    try {
      const res = await axios.delete(
        `${serverApi}/api/cms/user/${userListId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("삭제 성공", res);
      alert("삭제 성공");
      getUserList();
      onClose();
    } catch (error) {
      console.error("유저 삭제 에러", error);
      alert("삭제 실패");
    }
  };

  return (
    <>
      <div className="delete-modal__box">
        <div className="delete-modal__title">Delete 처리 하시겠습니까?</div>
        <div className="delete-modal__btn-box">
          <button className="delete-modal__cancel-btn" onClick={onClose}>
            아니오
          </button>
          <button className="delete-modal__confirm-btn" onClick={deleteUser}>
            네
          </button>
        </div>
      </div>
      <div className="delete-modal__background" onClick={onClose}></div>
    </>
  );
};

export default DeleteModal;
