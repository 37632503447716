import "../styles/UserDetail.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Copy from "../components/Copy";
import Cookies from "js-cookie";

import { Link } from "react-router-dom";
import TokenType from "../components/TokenType";

function UserDetail() {
  const { id } = useParams();
  const serverApi = process.env.REACT_APP_AWS_API;

  const [activeTitles, setActiveTitles] = useState([]);

  const toggleActive = (name) => {
    setActiveTitles((prev) => {
      // 제목이 이미 활성화되어 있다면 제거, 그렇지 않으면 추가
      return prev.includes(name)
        ? prev.filter((t) => t !== name)
        : [...prev, name];
    });
  };
  // =======================================================
  // 유저 상세정보
  // =======================================================
  // 토너먼트 정보 조회
  const [userDetailInfo, setUserDetailInfo] = useState([]);
  const [userGameIds, setUserGameIds] = useState([]);
  // 로그인 패널티 상태 관리
  const [noGameCount, setNoGameCount] = useState(0);
  const [loginBlockDate, setLoginBlockDate] = useState("");
  const [loginBlockTime, setLoginBlockTime] = useState("");
  // 토큰 정보
  const token = Cookies.get("token");
  // 토너먼트 정보 조회 함수
  const getUserDetailInfo = async () => {
    console.log("id", id);
    try {
      const res = await axios.get(`${serverApi}/api/cms/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserDetailInfo(res.data);
      setUserGameIds(res.data.game_list);
      setNoGameCount(res.data.game_no_ing_count);
      console.log("유저아이디", res);

      // login_block_date에서 날짜와 시간을 분리합니다.
      const loginBlockDateTime = res.data.login_block_date; // 예: "2024-03-19T13:15:00"
      if (loginBlockDateTime) {
        const [date, time] = loginBlockDateTime.split("T");
        const formattedDate = date; // "2024-03-19"
        const formattedTime = time.slice(0, 5); // "13:15", 초 단위 제거
        setLoginBlockDate(formattedDate);
        setLoginBlockTime(formattedTime);
      }
    } catch (error) {
      console.error("토너먼트 정보 조회 에러", error);
    }
  };
  // 직접 입력한 게임 미진행 카운트 변경 이벤트 핸들러
  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    // 입력값이 빈 문자열이 아니고, 숫자로 변환했을 때 0이 아닌 경우, 앞에 오는 0을 제거합니다.
    if (inputValue !== "" && Number(inputValue) !== 0) {
      inputValue = String(Number(inputValue)); // 앞에 오는 0을 제거하기 위해 숫자로 변환 후 다시 문자열로 변환
    }
    setNoGameCount(inputValue);
  };

  // id가 변경될 때마다 유저 상세정보 조회
  useEffect(() => {
    getUserDetailInfo();
  }, [id]);
  // =======================================================
  // 유저 패널티 기록 수정
  // =======================================================
  // 유저 패널티 기록 수정 함수
  const updateUserPenalty = async () => {
    try {
      let url = `${serverApi}/api/cms/user/${id}/login/block/update`;
      // 로그인 차단 날짜와 시간이 설정되어 있는 경우, 이를 URL에 추가합니다.
      // 그렇지 않은 경우, game_no_ing_count만 URL에 포함시킵니다.
      if (loginBlockDate !== "" && loginBlockTime !== "") {
        url += `?game_no_ing_count=${noGameCount}&login_block_date=${loginBlockDate}T${loginBlockTime}:00`;
      } else {
        url += `?game_no_ing_count=${noGameCount}`;
      }
      const res = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("유저 패널티 기록 수정 결과", res.data);
      alert("수정되었습니다.");
    } catch (error) {
      console.error("유저 패널티 기록 수정 에러", error);
      alert("수정에 실패했습니다.");
    }
  };
  // 패널티 초기화 버튼
  const resetPenalty = () => {
    setNoGameCount(0);
    setLoginBlockDate("");
    setLoginBlockTime("");
  };
  console.log("token", token);
  console.log("로그인 패널티", noGameCount, loginBlockDate, loginBlockTime);
  console.log("login_block_date", `${loginBlockDate}T${loginBlockTime}:00`);
  console.log("userGameIds", userGameIds);
  return (
    <>
      <div className="user--detail">
        <div className="user--detail--inner">
          <div className="user--detail--box">
            <div className="main--title">
              유저 상세정보
              <TokenType tokenType={userDetailInfo.network} />
            </div>
            <div className="user--detail--list">
              <dl>
                <dt>닉네임</dt>
                <dd>
                  {userDetailInfo.name ? userDetailInfo.name : "-"}
                  {userDetailInfo.name && <Copy text={userDetailInfo.name} />}
                </dd>
              </dl>
              <dl>
                <dt>지갑주소</dt>
                <dd>
                  <span>
                    {userDetailInfo.wallet_address
                      ? userDetailInfo.wallet_address
                      : "-"}
                    <Copy text={userDetailInfo.wallet_address} />
                  </span>
                </dd>
              </dl>
              <dl>
                <dt>IP주소</dt>
                <dd>
                  {userDetailInfo.ip_address ? userDetailInfo.ip_address : "-"}
                </dd>
              </dl>
              <dl>
                <dt>게임 미진행 카운트</dt>
                <dd>
                  <input
                    className="game--count--input"
                    type="number"
                    value={noGameCount}
                    onChange={handleInputChange}
                  />
                </dd>
              </dl>
              <dl>
                <dt>로그인 제외일</dt>
                <dd className="block--input--box">
                  <input
                    type="date"
                    value={loginBlockDate}
                    onChange={(e) => setLoginBlockDate(e.target.value)}
                  />
                  <input
                    type="time"
                    value={loginBlockTime}
                    onChange={(e) => setLoginBlockTime(e.target.value)}
                  />
                </dd>
              </dl>
              <div className="btn--box">
                <button className="user--btn btn--reset" onClick={resetPenalty}>
                  초기화
                </button>
                <button className="user--btn" onClick={updateUserPenalty}>
                  수정
                </button>
              </div>
            </div>
          </div>
          <div className="game--ids--box">
            <div className="game--ids--title">게임별 ID</div>
            <div className="game--ids--list">
              {userGameIds.map((game) => (
                <dl
                  key={game.id}
                  className={`list--item
                  ${game.username ? "registered--id" : ""}
                  ${
                    game.username && activeTitles.includes(game.name)
                      ? "active"
                      : ""
                  }`}
                  style={game.username ? { cursor: "pointer" } : {}}
                  onClick={() => game.username && toggleActive(game.name)}
                >
                  <dt>{game.name}</dt>
                  <dd>
                    <p>
                      {game.nickname && (
                        <>
                          Nick Name : {game.nickname || "닉네임 없음"}
                          <Copy text={game.nickname} />
                        </>
                      )}
                    </p>
                    {game.username && (
                      <p>
                        Game Id :&nbsp;
                        {game.username}
                        <Copy text={game.username} />
                      </p>
                    )}
                    <Link
                      className="game--record--btn"
                      to="/user-information"
                      state={{ userDetailInfo, gameName: game.name }}
                    >
                      경기기록
                    </Link>
                  </dd>
                </dl>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDetail;
