import React from "react";
import "./LinkModal.scss";

const LinkModal = ({ onClose, tournamentId, network }) => {
  console.log("tournamentId", tournamentId);
  // 링크 이동 실행 함수 tournament-detail/:id 로 이동, 새 창에서 열기
  const linkTournament = () => {
    // network에 따라 경로가 달라짐
    const url = (() => {
      switch (network) {
        case "Polygon":
          return `https://polygon.miracleplay.gg/tournament-detail/${tournamentId}`;
        case "Avalanche":
          return `https://avalanche.miracleplay.gg/tournament-detail/${tournamentId}`;
        case "Base":
          return `https://base.miracleplay.gg/tournament-detail/${tournamentId}`;
        default:
          return "";
      }
    })();
    window.open(url, "_blank"); // 새 창(탭)에서 URL 열기
    onClose(); // 모달 닫기
  };

  return (
    <>
      <div className="link-modal__box">
        <div className="modal__title">링크 이동을 하시겠습니까?</div>
        <div className="modal__btn-box">
          <button className="modal__cancel-btn" onClick={onClose}>
            취소
          </button>
          <button className="modal__confirm-btn" onClick={linkTournament}>
            확인
          </button>
        </div>
      </div>
      <div className="link-modal__background" onClick={onClose}></div>
    </>
  );
};

export default LinkModal;
