import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../styles/HighLight.scss";
import Cookies from "js-cookie";
// 이미지
import TokenType from "../components/TokenType";
import demoImg from "../assets/images/demoImg.png";
import ImageUpload from "../components/ImageUpload";
import loadingIcon from "../assets/images/loading.svg";
import HighLightDeleteModal from "../components/HighlightDeleteModal";

const HighLight = () => {
  // 네트워크 선택
  const { network } = useParams();
  const serverApi = process.env.REACT_APP_AWS_API;
  const token = Cookies.get("token");

  console.log("token", token);

  // 상태 관리 (탭 전환)
  const [isOrderView, setIsOrderView] = useState(true);
  const [isUploadView, setIsUploadView] = useState(false);
  const [uploadNumber, setUploadNumber] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  // 네트워크 변경 시 초기화
  useEffect(() => {
    handleOrderView();
  }, [network]);

  // 버튼 클릭 핸들러
  const handleOrderView = () => {
    setIsOrderView(true);
    setIsUploadView(false);
    setUploadNumber(null);
    setSelectedData(null);
  };

  return (
    <>
      <div className="highlight">
        <div className="highlight--inner">
          <div className="header--box">
            <div className="main--title">
              하이라이트 관리
              <TokenType tokenType={network} />
            </div>
          </div>
          {/* header--box --END-- */}
          <div className="highlight--type--btn">
            <button
              className={isOrderView ? "active" : ""}
              onClick={handleOrderView}
            >
              배치 순서
            </button>
            {isUploadView && (
              <button
                className={isUploadView ? "active" : ""}
                // onClick={handleUploadView}
              >
                사진 업로드
              </button>
            )}
          </div>
          {/* =============================배치 순서(이미지 순서) START============================== */}
          {isOrderView && (
            <OrderView
              network={network}
              serverApi={serverApi}
              token={token}
              setUploadNumber={setUploadNumber}
              setIsOrderView={setIsOrderView}
              setIsUploadView={setIsUploadView}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
            />
          )}
          {/* =============================배치 순서(이미지 순서) END============================== */}
          {/* =============================사진 업로드 START=== =========================== */}
          {isUploadView && (
            <UploadView
              network={network}
              serverApi={serverApi}
              token={token}
              setIsOrderView={setIsOrderView}
              setIsUploadView={setIsUploadView}
              uploadNumber={uploadNumber}
              selectedData={selectedData}
            />
          )}
          {/* =============================사진 업로드 END============================== */}
        </div>
      </div>
    </>
  );
};

export default HighLight;

/**
 * 하이라이이트 오더 뷰
 */
const OrderView = ({
  network,
  serverApi,
  token,
  setUploadNumber,
  setIsOrderView,
  setIsUploadView,
  selectedData,
  setSelectedData,
}) => {
  const highLightArr = new Array(10)
    .fill(null)
    .map((item, index) => ({ numbering: index + 1 }));
  const [dataList, setDataList] = useState([]);
  const [highLightDeleteModal, setHighLightDeleteModal] = useState(false);

  //=======================
  // 하이라이트 데이터 Fetch
  //=======================

  const getHighLight = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/game/high/light/${network}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let copy = [...highLightArr];
      res.data.forEach((item) => {
        copy[item.numbering - 1] = item;
      });
      setDataList(copy);
    } catch (e) {
      alert("하이라이트 목록 조회 중 오류 발생!!");
      console.log(e);
    }
  };

  //=========================
  // 핸들 딜리트
  //=========================
  const handleDelete = (data) => {
    setSelectedData(data);
    setHighLightDeleteModal(true);
  };

  //=========================
  // 핸들 업로드
  //=========================
  const handleUpload = (number) => {
    setUploadNumber(number);
    setSelectedData(null);
    setIsOrderView(false);
    setIsUploadView(true);
  };

  const handleUpdate = (data) => {
    setUploadNumber(data.numbering);
    setSelectedData(data);
    setIsOrderView(false);
    setIsUploadView(true);
  };

  //=========================
  // URL 편집
  //=========================
  const formattedURL = (url) => {
    if (typeof url === "string") {
      const split = url.split("/");
      return `${network}/${split[split.length - 1]}`;
    } else {
      return "URL 입력 필요함";
    }
  };

  useEffect(() => {
    getHighLight();
  }, [network]);

  return (
    <section className="highlight--img--list--box--cover">
      <div className="highlight--img--list--box">
        {dataList.map((item) => (
          <dl className="item--box" key={item.numbering}>
            <dt>
              <span>{item.numbering}</span>
              <p
                onClick={
                  !item.pc_image
                    ? () => handleUpload(item.numbering)
                    : () => handleUpdate(item)
                }
              >
                {item.pc_image && <img src={item.pc_image} alt="img" />}
              </p>
            </dt>
            <dd style={{ color: item.url ? "" : "#6e6e6e" }}>
              {formattedURL(item.url)}
            </dd>
            {item.id && (
              <button
                onClick={() => {
                  handleDelete(item);
                }}
              >
                삭제
              </button>
            )}
          </dl>
        ))}
      </div>
      {highLightDeleteModal && (
        <HighLightDeleteModal
          setHighLightDeleteModal={setHighLightDeleteModal}
          selectedData={selectedData}
          getHighLight={getHighLight}
        />
      )}
    </section>
  );
};

/**
 * 하이라이트 업로드 뷰
 */
const UploadView = ({
  network,
  serverApi,
  token,
  setIsOrderView,
  setIsUploadView,
  uploadNumber,
  selectedData,
}) => {
  // 업로드 시 필드 값으로 들어가는 상태 값
  const [urlAddress, setUrlAddress] = useState("");
  const [uploadStartTime, setUploadStartTime] = useState("");
  const [uploadEndTime, setUploadEndTime] = useState("");
  const [selectedPcImage, setSelectedPcImage] = useState("");
  const [selectedTabImage, setSelectedTabImage] = useState("");
  const [selectedMobileImage, setSelectedMobileImage] = useState("");

  const [loading, setLoading] = useState(false);

  //======================
  // 업로드 & 업데이트 ( 현재 업로드 및 업데이트의 로직이 완전히 동일하여 조건문으로 url을 변경해주고 있습니다. 추후에 업데이트와 업로드 로직의 차이점이 발생할 시에는 분리가 필요합니다. )
  //======================
  const handleImageUpload = async () => {
    // data가 다 입력되어있을 경우 에만 요청

    let url;
    if (selectedData) url = `api/cms/game/high/light/${selectedData.id}/update`;
    else url = `api/cms/game/high/light`;

    if (
      !uploadNumber ||
      !urlAddress ||
      !uploadStartTime ||
      !uploadEndTime ||
      !selectedPcImage ||
      !selectedMobileImage ||
      !selectedTabImage
    ) {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    const data = {
      numbering: uploadNumber,
      url: urlAddress,
      start_date: uploadStartTime,
      end_date: uploadEndTime,
      network,
    };
    const formData = new FormData();
    formData.append("payload", JSON.stringify(data));
    formData.append("pc_image", selectedPcImage);
    formData.append("mobile_image", selectedMobileImage);
    formData.append("tablet_image", selectedTabImage);

    try {
      setLoading(true);
      console.log("토큰", token);
      const res = await axios.post(`${serverApi}/${url}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("이미지 업로드 결과", res);
      setIsOrderView(true);
      setIsUploadView(false);
    } catch (error) {
      console.error("이미지 업로드 에러", error);
    } finally {
      setLoading(false);
    }
    console.log("보내는 데이터", formData.get("pc_image"));
  };

  useEffect(() => {
    if (selectedData) {
      setUrlAddress(selectedData.url);
      setUploadStartTime(selectedData.start_date);
      setUploadEndTime(selectedData.end_date);
    }
  }, []);

  return (
    <>
      <section className="highlight--up-load--list--box">
        <div className="left--content">
          <dl>
            <dt>순서</dt>
            <dd>
              <input type="number" value={uploadNumber} readOnly />
            </dd>
          </dl>
          <dl>
            <dt>URL 주소</dt>
            <dd>
              <input
                type="text"
                value={urlAddress}
                onChange={(e) => {
                  setUrlAddress(e.target.value);
                }}
              />
              {/* <button className="up--load--btn" type="button">
                업로드
              </button> */}
            </dd>
          </dl>
          <dl>
            <dt>업로드 시작 시간 (UTC+0)</dt>
            <dd>
              <p>
                <input
                  className="date"
                  value={uploadStartTime}
                  type="datetime-local"
                  onChange={(e) => {
                    setUploadStartTime(e.target.value);
                  }}
                />
              </p>
            </dd>
          </dl>
          <dl>
            <dt>업로드 종료 시간 (UTC+0)</dt>
            <dd>
              <input
                className="date"
                value={uploadEndTime}
                type="datetime-local"
                onChange={(e) => {
                  setUploadEndTime(e.target.value);
                }}
              />
            </dd>
          </dl>
          {/* <dl className="img--up--load">
          <dt>
            이미지
            <p>PC 이미지 <span>1 img</span></p>
          </dt>
          <dd
            className={`upload-container ${dragging ? 'active' : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <p className="file--up--load--txt">
              <img src={imgUpLoadeIcon} alt="imgUpLoad"/>
              <span>파일 업로드 하기</span>
            </p>
            <p className="img--txt">이미지를 끌어오세요.<span>529 x 260 PX</span></p>
            <input type="file" accept="image/*" className="upload-input"  onChange={handleImageChange} />
          </dd>
        </dl> */}
          <div className="img--up--load--list">
            <ImageUpload
              title="PC 이미지"
              size="592 x 260 PX"
              index="1"
              setSelectedDeviceImage={setSelectedPcImage}
            />
            <ImageUpload
              title="탭 이미지"
              size="360 x 380 PX"
              index="2"
              setSelectedDeviceImage={setSelectedTabImage}
            />
            <ImageUpload
              title="모바일 이미지"
              size="500 x 660 PX"
              index="3"
              setSelectedDeviceImage={setSelectedMobileImage}
            />
          </div>
        </div>
        {/* <div className="right--content--cover">
        <p className="right--title">예상 이미지</p>
        <div className="right--content">
          <div className="right--content--item pc">
            <p>1 img</p>
            <span>PC 예상 이미지</span>
            {selectedImage && (
                <img src={selectedImage} alt="Selected" />
              )}
          </div>
          <div className="right--content--item tab">
            <p>2 img</p>
            <span>탭 예상 이미지</span>
            <img src={demoImg} alt="img" />
          </div>
          <div className="right--content--item mobile">
            <p>3 img</p>
            <span>모바일 예상 이미지</span>
            <img src={demoImg} alt="img" />
          </div>
        </div>
      </div> */}
        {/* right--content--cover--END-- */}
        <button
          type="button"
          className="up--load--end--btn"
          onClick={handleImageUpload}
          disabled={loading}
        >
          업로드 하기
        </button>
        {loading && (
          <p className="loading--icon">
            <img src={loadingIcon} alt="loading--img" />
          </p>
        )}
      </section>
    </>
  );
};
