import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./CancelModal.scss";

const CancelModal = ({ onClose, tournamentId, getTournamentList }) => {
  console.log("tournamentId", tournamentId);
  const token = Cookies.get("token");
  const serverApi = process.env.REACT_APP_AWS_API;
  // 토너먼트 캔슬 실행 함수
  const cancelTournament = async () => {
    try {
      const res = await axios.post(
        `${serverApi}/api/cms/tournament/${tournamentId}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("cancelTournament", res);
      getTournamentList();
      onClose();
    } catch (error) {
      console.error("토너먼트 캔슬 에러", error);
    }
  };

  return (
    <>
      <div className="cancel-modal__box">
        <div className="cancel-modal__title">Cancel 처리 하시겠습니까?</div>
        <div className="cancel-modal__btn-box">
          <button className="cancel-modal__cancel-btn" onClick={onClose}>
            취소
          </button>
          <button
            className="cancel-modal__confirm-btn"
            onClick={cancelTournament}
          >
            확인
          </button>
        </div>
      </div>
      <div className="cancel-modal__background" onClick={onClose}></div>
    </>
  );
};

export default CancelModal;
