import "../styles/GameRecord.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
// 이미지

import arrImg from "../assets/images/LineArr.png";

function GameRecord() {
  const serverApi = process.env.REACT_APP_AWS_API;
  // 토큰 정보
  const token = Cookies.get("token");
  // 게임 목록
  const games = [
    "BUBBLE SHOOTER",
    "MIRACLE BINGO",
    "BATTLE TANK",
    "MIRACLE SLIDE",
    "MIRACLE GOLF",
    "BEAR BAKER",
    "MIRACLE HOOP",
    "DOTA2",
    "BRICK GALAXY",
    "MIRACLE JUWELS",
    "HOMERUN GIRL",
    "VILLAINS",
    "STORM STRIKER",
  ];

  const gameModes = {
    DOTA2: ["new_player"],
    "MIRACLE GOLF": ["par3", "par4", "par5", "All"],
    "MIRACLE JUWELS": ["normal", "bomb", "advance", "time"],
    // "HOMERUN GIRL": ["homerun_battle", "versus_mode"],
    VILLAINS: ["solo", "duo"],
    "STORM STRIKER": ["AI Mode", "Casual Mode"],
  };

  // 선택한 game name
  const [gameName, setGameName] = useState("BUBBLE SHOOTER");
  // 입력한 유저 UID
  const [userUid, setUserUid] = useState("");
  // 시작 시간
  const [startTime, setStartTime] = useState("");
  // startTime 받은 입력값을 timestamp로 변환
  const [formattedStartTime, setFormattedStartTime] = useState("");
  // 종료 시간
  const [endTime, setEndTime] = useState("");
  // endTime 받은 입력값을 timestamp로 변환
  const [formattedEndTime, setFormattedEndTime] = useState("");
  // 게임 모드
  const [gameMode, setGameMode] = useState(null);
  // 게임모드 드롭다운
  const [gameModeDropdown, setGameModeDropdown] = useState(false);

  // 날짜 입력시 '-' 자동 입력
  const formatDateTime = (input) => {
    input = input.replace(/[^0-9]/g, ""); // 숫자가 아닌 문자 제거
    let formattedInput = "";

    if (input.length > 4) {
      formattedInput += input.substring(0, 4) + "-";
      if (input.length > 6) {
        formattedInput += input.substring(4, 6) + "-";
        if (input.length > 8) {
          formattedInput += input.substring(6, 8) + " ";
          if (input.length > 10) {
            formattedInput += input.substring(8, 10) + ":";
            if (input.length > 12) {
              formattedInput += input.substring(10, 12) + ":";
              if (input.length > 14) {
                formattedInput += input.substring(12, 14);
              } else {
                formattedInput += input.substring(12);
              }
            } else {
              formattedInput += input.substring(10);
            }
          } else {
            formattedInput += input.substring(8);
          }
        } else {
          formattedInput += input.substring(6);
        }
      } else {
        formattedInput += input.substring(4);
      }
    } else {
      formattedInput = input;
    }
    return formattedInput;
  };

  // 시작 시간 핸들러
  const handleStartTimeChange = (event) => {
    setStartTime(formatDateTime(event.target.value));
    setFormattedStartTime(
      new Date(event.target.value).getTime() / 1000 + 32400
    );
  };

  // 종료 시간 핸들러
  const handleEndTimeChange = (event) => {
    setEndTime(formatDateTime(event.target.value));
    setFormattedEndTime(new Date(event.target.value).getTime() / 1000 + 32400);
  };

  // 레코드 조회
  const [recordList, setRecordList] = useState([]);
  // 레코드 조회 후 에러메시지
  const [recordListTxt, setRecordListTxt] =
    useState("유저 아이디를 검색해 주세요.");
  // 레코드 로딩중
  const [recordListIsLoading, setRecordListIsLoading] = useState(false);
  // 레코드 조회 함수
  const getRecordList = async () => {
    setRecordListIsLoading(true);
    setRecordList([]);
    try {
      const params = {
        game_name: gameName, // 게임 이름
        username: userUid, // 사용자 이름
        startTime: formattedStartTime, // 시작 시간
        endTime: formattedEndTime, // 종료 시간
        mode_type: gameModes[gameName] ? gameMode : "",
      };
      console.log("파라미터들", params);
      const res = await axios.get(`${serverApi}/api/game/real/score/search`, {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 사용한 인증 헤더
        },
        params, // 쿼리 파라미터를 요청에 포함
      });
      console.log("recordList", res.data);
      if (res.data.length === 0) {
        setRecordListTxt("검색 결과가 없습니다.");
      } else {
        setRecordListTxt("");
      }
      // 조회 데이터가 json 형태로 올 경우에는 parse 하여 저장
      if (
        typeof res.data === "string" &&
        res.data.startsWith("[{") &&
        res.data.endsWith("}]")
      ) {
        // 'NaN' 값을 'null'로 전처리합니다.
        let preprocessedData = res.data.replace(/\bNaN\b/g, "null");
        try {
          const parsedData = JSON.parse(preprocessedData);
          console.log("파싱된 데이터", parsedData);
          setRecordList(parsedData);
        } catch (error) {
          // JSON 형식이 아니거나 파싱 중 에러가 발생한 경우,
          console.error("Parsing error:", error);
        }
      } else {
        // JSON 형식으로 추정되지 않는 경우, 데이터를 그대로 저장합니다.
        setRecordList(res.data);
      }
      console.log("recordList", recordList);
    } catch (error) {
      console.error("recordListTxt", error);
      setRecordListTxt("조회에 실패 했습니다.");
    } finally {
      setRecordListIsLoading(false);
    }
  };
  // 게임 이름 선택
  const handleGameName = (e) => {
    setGameName(e.target.innerText);
  };

  // 유저 UID 입력
  const handleUserUid = (e) => {
    setUserUid(e.target.value);
  };
  // 게임 이름 선택이 바뀌면 초기화
  useEffect(() => {
    setRecordList([]);
    setRecordListTxt("유저 아이디를 검색해 주세요");
  }, [gameName]);
  // ==== 타임 스탬프 변환 관련 ====
  const [startTimeStamp, setStartTimeStamp] = useState("");
  const [endTimeStamp, setEndTimeStamp] = useState("");
  const [playTime, setPlayTime] = useState("");
  // 변환된 값을 저장할 상태
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [playTimeInMinutes, setPlayTimeInMinutes] = useState("");
  // 타임스탬프 변환 함수
  const convertTimeStamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toUTCString().replace("GMT", "UTC+0");
  };
  // 플레이타임(초)를 플레이타임(분, 초)로 변환
  const convertPlayTime = (playTime) => {
    const minutes = Math.floor(playTime / 60);
    const seconds = playTime % 60; // 남은 초를 계산
    return `${minutes}분 ${seconds}초`;
  };

  // 버튼 클릭 핸들러 함수들
  const handleStartTimestampButtonClick = () => {
    const convertedDate = convertTimeStamp(startTimeStamp); // 변환
    setStartDateTime(convertedDate); // 변환된 값을 저장
  };

  const handleEndTimestampButtonClick = () => {
    const convertedDate = convertTimeStamp(endTimeStamp); // 변환
    setEndDateTime(convertedDate); // 변환된 값을 저장
  };
  // (초까지 표시 되어야 하는지 확인)
  const handlePlayTimeButtonClick = () => {
    const convertedPlayTime = convertPlayTime(playTime); // 변환
    setPlayTimeInMinutes(convertedPlayTime); // 변환된 값을 저장
  };

  console.log("gameName", gameName);
  console.log("userUid", userUid);
  console.log("startTime", startTime);
  console.log("endTime", endTime);
  console.log("formattedStartTime", formattedStartTime);
  console.log("formattedEndTime", formattedEndTime);

  useEffect(() => {
    setGameMode("");
    setGameModeDropdown(false);
  }, [gameName]);

  return (
    <div className="game--record">
      <div className="game--inner">
        <div className="game--inner--box">
          <div className="header--box">
            <div className="game--title">게임별 기록 조회</div>
          </div>
          <div className="game--record--list--box">
            <div className="game--name--list">
              <div className="title--text">게임명</div>
              <ul>
                {games.map((game) => (
                  <li
                    key={game}
                    onClick={handleGameName}
                    className={gameName === game ? "active" : ""}
                  >
                    {game}
                  </li>
                ))}
              </ul>
            </div>
            <div className="user--uid--box">
              <dl>
                <dt>유저 UID</dt>
                <dd>
                  <input
                    type="text"
                    placeholder="유저 UID"
                    onChange={handleUserUid}
                    value={userUid}
                  />
                </dd>
              </dl>
              <div className="arr">
                <img src={arrImg} alt="arrIcon" />
              </div>
              <dl>
                <dt>시작 시간 (UTC+0)</dt>
                <dd>
                  <input
                    type="text"
                    onChange={handleStartTimeChange}
                    value={startTime}
                    placeholder="2000-01-01 10:10:10"
                  />
                </dd>
              </dl>
              <dl>
                <dt>종료 시간 (UTC+0)</dt>
                <dd>
                  <input
                    type="text"
                    onChange={handleEndTimeChange}
                    value={endTime}
                    placeholder="2000-01-01 10:10:10"
                  />
                </dd>
              </dl>
              {gameModes[gameName] && (
                <dl>
                  <dt>모드 선택</dt>
                  <dd
                    className="mode-dropdown__select"
                    onClick={() => setGameModeDropdown((prev) => !prev)}
                  >
                    {!gameMode ? "All" : gameMode}
                    {gameModeDropdown && (
                      <ul className="mode-dropdown__list">
                        <li
                          className="mode-dropdown__list-item"
                          onClick={() => setGameMode("")}
                        >
                          All
                        </li>
                        {gameModes[gameName].map((item, index) => (
                          <li
                            key={index}
                            className="mode-dropdown__list-item"
                            onClick={() => setGameMode(item)}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    )}
                  </dd>
                </dl>
              )}
              <button className="search--btn" onClick={getRecordList}>
                검색
              </button>
            </div>
            {/* === 코드뷰 관련 코드 === */}
            <div className="code--view">
              <div className="code--view--inner">
                {(!recordList || recordList.length === 0) &&
                  !recordListIsLoading && (
                    <div className="none--box">{recordListTxt}</div>
                  )}

                {recordListIsLoading && (
                  <div className="none--box">로딩중..</div>
                )}
                {/* aUnU2Tk3KPuNcYtI 버블 ,1280754469 */}
                {recordList &&
                  recordList.length !== 0 &&
                  (!Array.isArray(recordList) ? (
                    <p>{recordList}</p>
                  ) : (
                    <dl>
                      {recordList?.map((record, index) => (
                        <div key={index} className="view--box">
                          <dt>
                            경기 기록 : <p>{index + 1}</p>
                          </dt>
                          {/* key가 scores인 키 값은 출력 안나오게 (미라클 슬라이드에서만 적용됨)*/}
                          {Object.entries(record).map(
                            ([key, value]) =>
                              key !== "scores" &&
                              key !== "z_scores" && (
                                <dd key={key}>
                                  <p>{key}:</p> {value}
                                </dd>
                              )
                          )}
                        </div>
                      ))}
                    </dl>
                  ))}
              </div>
            </div>
            {/* ======== 우측 타임스탬프 관련 코드 =======*/}
            <div className="time--utc--box">
              {/* 시작 시간 타임스탬프 입력 항목 */}
              <div className="time--item">
                <dl>
                  <dt>타임스탬프</dt>
                  <dd>
                    <input
                      placeholder="startTime"
                      onChange={(e) => setStartTimeStamp(e.target.value)}
                      value={startTimeStamp}
                    />
                  </dd>
                </dl>
                <div className="input--btn">
                  <button onClick={handleStartTimestampButtonClick}>
                    입력
                  </button>
                </div>
                <dl>
                  <dt>시작 시간 (UTC+0)</dt>
                  <dd>{startDateTime}</dd>
                </dl>
              </div>
              {/* 종료 시간 타임스탬프 입력 항목 */}
              <div className="time--item">
                <dl>
                  <dt>타임스탬프</dt>
                  <dd>
                    <input
                      type="number"
                      placeholder="endTime"
                      onChange={(e) => setEndTimeStamp(e.target.value)}
                      value={endTimeStamp}
                    />
                  </dd>
                </dl>
                <div className="input--btn">
                  <button onClick={handleEndTimestampButtonClick}>입력</button>
                </div>
                <dl>
                  <dt>종료 시간 (UTC+0)</dt>
                  <dd>{endDateTime}</dd>
                </dl>
              </div>
              {/* 플레이 타임 입력 항목 */}
              <div className="time--item">
                <dl>
                  <dt>플레이 타임(초)</dt>
                  <dd>
                    <input
                      placeholder="플레이타임 초"
                      type="number"
                      onChange={(e) => setPlayTime(e.target.value)}
                      value={playTime}
                    />
                  </dd>
                </dl>
                <div className="input--btn">
                  <button onClick={handlePlayTimeButtonClick}>입력</button>
                </div>
                <dl>
                  <dt>플레이 타임 (분)</dt>
                  <dd>{playTimeInMinutes}</dd>
                </dl>
              </div>
            </div>
          </div>
          {/* game--record--list--box--END-- */}
        </div>
      </div>
    </div>
  );
}

export default GameRecord;
