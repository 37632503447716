import React, { useEffect } from "react";
import axios from "axios";
import "./HighlightDeleteModal.scss";
import Cookies from "js-cookie";

const HighLightDeleteModal = ({
  setHighLightDeleteModal,
  selectedData,
  getHighLight,
}) => {
  const token = Cookies.get("token");
  const serverApi = process.env.REACT_APP_AWS_API;

  // 하이라이트 삭제 처리 함수.
  const deleteUser = async () => {
    try {
      const res = await axios.delete(
        `${serverApi}/api/cms/game/high/light/${selectedData.id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHighLightDeleteModal(false);
      getHighLight();
    } catch (error) {
      console.error("유저 삭제 에러", error);
      alert("삭제 실패");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      <div className="delete-modal__box">
        <div className="delete-modal__title">Delete 처리 하시겠습니까?</div>
        <div className="delete-modal__btn-box">
          <button
            className="delete-modal__cancel-btn"
            onClick={() => setHighLightDeleteModal(false)}
          >
            아니오
          </button>
          <button className="delete-modal__confirm-btn" onClick={deleteUser}>
            네
          </button>
        </div>
      </div>
      <div
        className="delete-modal__background"
        onClick={() => setHighLightDeleteModal(false)}
      ></div>
    </>
  );
};

export default HighLightDeleteModal;
