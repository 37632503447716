import React from "react";
import "./Copy.scss";
const CopyButton = ({ text }) => {
  console.log("text", text);
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    alert(text + "가 복사되었습니다.");
  };

  return (
    <button className="copy-btn" onClick={handleCopy}>
      복사
    </button>
  );
};

export default CopyButton;
