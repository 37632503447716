import React from "react";
import "./TokenType.scss";

import tokenPolygon from "../assets/images/PolygonIcon.svg";
import tokenAvalanche from "../assets/images/avalancheIcon.png";
import tokenBase from "../assets/images/baseCoin.png";
import tokenOpbnb from "../assets/images/opbnbCoin.png";

const TokenType = ({ tokenType }) => {
  // 이미지 소스와 토큰 이름 매핑
  const tokenInfo = {
    Polygon: { src: tokenPolygon, name: "POLYGON" },
    Avalanche: { src: tokenAvalanche, name: "AVALANCHE" },
    Base: { src: tokenBase, name: "BASE" },
    opBNB: { src: tokenOpbnb, name: "OPBNB" },
    Intro: { src: null, name: "INTRO" },
  };

  // tokenType에 따른 정보가 없을 경우 null 반환
  if (!tokenInfo[tokenType]) return null;

  const { src, name } = tokenInfo[tokenType];

  return (
    <p className={`token--type ${tokenType.toLowerCase()}`}>
      {src && <img src={src} alt={name} />}
      {name}
    </p>
  );
};

export default TokenType;
